
















































































import FormMedia from '@/components/form/FormMedia.vue';
import FormValidationMixin from '@/mixins/FormValidationMixin';
import { ApiError } from '@/model/ApiError';
import { HowToItem } from '@/model/HowTo';
import { PropType } from 'vue';
import { mixins } from 'vue-class-component';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { maxLength, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

@Component({
    components: {
        FormMedia,
    },
    methods: {
        ...mapActions({
            getAssetUrl: 's3Storage/getPresignedUrl',
        }),
    },
})
export default class HowToPages extends mixins(FormValidationMixin) {
    @Prop({ type: Object as PropType<HowToItem> }) item!: HowToItem;
    @Prop({ type: Boolean, default: false }) loading!: boolean;

    getAssetUrl!: (key?: string | null) => Promise<string | ApiError | null>;

    formData = {
        titleValue: this.item.title,
        textValue: this.item.content,
    };

    src = '';
    file = {} as File;

    @Validations()
    validations = {
        formData: {
            titleValue: { required, maxLength: maxLength(50) },
        },
    };

    @Watch('item')
    updateAsset(): void {
        this.getAsset(this.item.assetKey);
        this.formData = {
            titleValue: this.item.title,
            textValue: this.item.content,
        };
    }

    mounted(): void {
        this.getAsset(this.item.assetKey);
    }

    changeImage(file: File): void {
        this.file = file;
    }

    getAsset(key?: string | null): void {
        this.getAssetUrl(key).then((url) => {
            this.src = url as string;
        });
    }

    update(): void {
        this.$v.$touch();
        if (!this.$v.$invalid) {
            this.onUpdate();
        }
    }

    @Emit()
    onUpdate(): HowToItem {
        return {
            ...this.item,
            title: this.formData.titleValue,
            content: this.formData.textValue,
            imageFile: this.file,
        };
    }
}
