






















import FormMedia from '@/components/form/FormMedia.vue';
import HowToPage from '@/components/howto/Page.vue';
import ToastMixin from '@/mixins/ToastMixin';
import { HowToItem } from '@/model/HowTo';
import ToastVariant from '@/model/toast/toast-variant';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapMutations } from 'vuex';

@Component({
    components: {
        FormMedia,
        HowToPage,
        draggable,
    },
    computed: {
        ...mapGetters({
            items: 'howToStorage/getPages',
        }),
    },
    methods: {
        ...mapActions({
            fetch: 'howToStorage/fetch',
            update: 'howToStorage/update',
            updateOrder: 'howToStorage/updateOrder',
            uploadImage: 'howToStorage/uploadImage',
        }),
        ...mapMutations({
            setItems: 'howToStorage/setPages',
        }),
    },
})
export default class HowToPages extends mixins(ToastMixin) {
    fetch!: () => Promise<void>;
    update!: (request: HowToItem) => Promise<void>;
    updateOrder!: (request: HowToItem) => Promise<void>;
    setItems!: (items: HowToItem[] | null) => void;
    uploadImage!: (request: HowToItem) => Promise<string | null>;

    items!: HowToItem[] | null;

    loadingItem: null | number = null;
    loading = false;

    get itemList(): HowToItem[] | null {
        return this.items;
    }

    set itemList(value: HowToItem[] | null) {
        this.setItems(value);
    }

    mounted(): void {
        this.loading = true;
        this.fetch().finally(() => {
            this.loading = false;
        });
    }

    onUpdate(item: HowToItem): void {
        this.loadingItem = item.id;
        this.uploadImage(item)
            .then((imgKey: string | null) => {
                this.update({
                    ...item,
                    assetKey: imgKey ?? item.assetKey,
                })
                    .then(() => {
                        this.showToast(
                            this.$t('how-to.update-success').toString(),
                            this.$t('success-title').toString(),
                            ToastVariant.SUCCESS,
                        );
                    })
                    .finally(() => {
                        this.loadingItem = null;
                    });
            })
            .catch(() => {
                this.loadingItem = null;

                this.showToast(
                    this.$t('how-to.asset-upload-fail').toString(),
                    this.$t('error-title').toString(),
                    ToastVariant.DANGER,
                );
            });
    }

    onMoved({
        moved: { newIndex, element },
    }: {
        moved: { newIndex: number; element: HowToItem };
    }): void {
        const edited = { ...element };

        edited.order = newIndex + 1;

        this.loadingItem = edited.id;
        this.updateOrder(edited).then(() => {
            this.fetch().finally(() => {
                this.loadingItem = null;
            });
        });
    }
}
