var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    staticClass: "overlay",
    attrs: {
      "show": _vm.loading,
      "opacity": 0.55,
      "spinner-type": "grow"
    }
  }, [_c('div', {
    staticClass: "page-container d-flex flex-column"
  }, [_c('div', {
    staticClass: "handle-class d-flex align-items-center justify-content-between"
  }, [_c('div'), _c('span', {
    staticClass: "order-text"
  }, [_vm._v(_vm._s(_vm.item.order))]), _c('img', {
    staticClass: "handle icon",
    attrs: {
      "src": require("@/assets/icon/handle.svg")
    }
  })]), _c('div', {
    staticClass: "wrapper"
  }, [_c('form-media', {
    staticClass: "media",
    attrs: {
      "asset": _vm.src
    },
    on: {
      "on-change": _vm.changeImage
    }
  }), _c('b-form-input', {
    staticClass: "title-input mt-3 text-center font-weight-bold",
    attrs: {
      "id": "input-".concat(_vm.item.id),
      "type": "text",
      "placeholder": _vm.$t('how-to.form.title'),
      "state": _vm.getStateWithoutSubmit('titleValue')
    },
    model: {
      value: _vm.$v.formData.titleValue.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formData.titleValue, "$model", $$v);
      },
      expression: "$v.formData.titleValue.$model"
    }
  }), !_vm.$v.formData.titleValue.required ? _c('b-form-invalid-feedback', {
    staticClass: "text-center",
    attrs: {
      "id": "input-".concat(_vm.item.id, "-feedback")
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation.required', {
    field: _vm.$t('how-to.form.title')
  })) + " ")]) : _vm._e(), !_vm.$v.formData.titleValue.maxLength ? _c('b-form-invalid-feedback', {
    staticClass: "text-center",
    attrs: {
      "id": "input-".concat(_vm.item.id, "-feedback")
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation.max-length', {
    field: _vm.$t('how-to.form.title'),
    max: _vm.$v.formData.titleValue.$params.maxLength.max
  })) + " ")]) : _vm._e(), _c('b-form-textarea', {
    staticClass: "title-input text-center",
    attrs: {
      "id": "area-".concat(_vm.item.id),
      "placeholder": _vm.$t('how-to.form.description'),
      "no-resize": "",
      "rows": "3",
      "max-rows": "3",
      "wrap": "hard"
    },
    model: {
      value: _vm.formData.textValue,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "textValue", $$v);
      },
      expression: "formData.textValue"
    }
  }), _c('div', {
    staticClass: "d-flex mt-5"
  }, [_c('div', {
    staticClass: "dot",
    class: {
      fill: _vm.item.order === 1
    }
  }), _c('div', {
    staticClass: "dot ml-1",
    class: {
      fill: _vm.item.order === 2
    }
  }), _c('div', {
    staticClass: "dot ml-1",
    class: {
      fill: _vm.item.order === 3
    }
  })]), _c('b-button', {
    staticClass: "button mt-4",
    attrs: {
      "type": "submit",
      "variant": "outline-light"
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('how-to.page.update')) + " ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }