var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('draggable', {
    staticClass: "d-flex",
    attrs: {
      "group": "howtos",
      "disabled": _vm.loading || _vm.loadingItem,
      "handle": ".handle"
    },
    on: {
      "change": _vm.onMoved
    },
    model: {
      value: _vm.itemList,
      callback: function callback($$v) {
        _vm.itemList = $$v;
      },
      expression: "itemList"
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('how-to-page', {
      key: item.id,
      staticClass: "ml-3",
      attrs: {
        "item": item,
        "loading": _vm.loadingItem === item.id || _vm.loading
      },
      on: {
        "on-update": _vm.onUpdate
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }