




import HowToPages from '@/active-components/howto/Pages.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        HowToPages,
    },
})
export default class HowTo extends Vue {}
